<template>
  <v-container
    id="data-tables"
    class="fill-height align-start"
    fluid
    tag="section"
  >
    <v-container
      fluid
    >
      <v-col
        cols="12"
        md="4"
      >
        <v-card
          class="mx-auto elevation-20"
          color="rgba(214, 214, 214)"
          dark
          style="max-width: 400px;"
        >
          <v-row justify="space-between">
            <v-col cols="8">
              <v-card-title primary-title>
                <div>
                  <div class="text-h5">
                    {{ $t('memberCardId') }}:{{ memberCardData.id }}
                  </div>
                  <div>{{ $t('totalAmount') }}:{{ memberCardData.totalAmount }}</div>
                  <div>{{ $t('leftAmount') }}:{{ memberCardData.leftAmount }}</div>
                </div>
              </v-card-title>
            </v-col>
            <v-img
              class="shrink ma-2"
              contain
              height="120px"
              src="@/assets/logonew.png"
              style="flex-basis: 120px"
            />
          </v-row>
          <v-divider dark/>
          <v-card-actions class="pa-4">
            {{ $t('createdAt') }}:{{ memberCardData.createdAt }}
            <v-spacer/>
            <span class="grey--text text--lighten-2 text-caption mr-2"/>
            <v-rating
              background-color="white"
              color="yellow accent-4"
              dense
              half-increments
              hover
              size="18"
            />
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="12"
      >
        <ik-data-table
          :entity-name=" $t('memberCard_sales_records')"
          :filter="filter"
          :model="model"
          :use-action="false"
          :use-select="false"
          icon="mdi-cards"
        >
          <template #footer>
            <v-toolbar
              color="white"
              flat
            />
          </template>
        </ik-data-table>
      </v-col>
    </v-container>
  </v-container>
</template>

<script>

  import { IkDataTable } from 'metaflow-js'
  import MemberCardPaymentLog from '../../../model/bills/MemberCardPaymentLog'
  import { getOneMemberCardDetail } from '../../../common/Utlis/api'

  export default {
    components: {
      IkDataTable,
    },
    props: {
      id: {
        default: -1,
        type: Number,
      },
    },
    data: function () {
      return ({
        model: MemberCardPaymentLog,
        memberCardData: '',
      })
    },
    computed: {
      filter () {
        return { id: this.id }
      },
    },
    created () {
      this.loadDetail(this.id)
    },
    methods: {
      loadDetail (id) {
        getOneMemberCardDetail(id).then(res => {
          this.memberCardData = res.content[0]
        })
      },
    },
  }
</script>
