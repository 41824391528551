import { IKDataEntity } from 'metaflow-js'
import hillo from 'hillo'

const entity = {
  id: {
    type: IKDataEntity.Types.Integer,
    displayName: 'id',
    tableConfig: {
      align: 'start',
    },
  },
  orderId: {},
  createdAt: {
    displayName: 'time_to_use', // 会员卡消费记录的时间
  },
  amount: {},
}

const config = {
  load: async function (filter) {
    return (await hillo.get('MemberCard.php', Object.assign({
      op: 'getOne',
      withConsumeLog: true,
    }, ...filter)))
      .content.consumeLog
  },
}

export default IKDataEntity.ModelFactory(entity, config)
